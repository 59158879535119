<template>
  <div class="mainCont">
    <div class="background">
      <div class="backgroundImg_n_DragArea">
        <div class="firstLine">
          <div v-for="index in 6" :key="index" class="buttonBox bottombox">
            <img v-if="index > 1" class="cloud" :src="cloudImage" />
            <div
              v-if="index > 1"
              class="button"
              @click="foundAnswer(index - 2)"
              v-bind:class="{
                yellowBG: chineseList[index - 2].clicked == true,
              }"
            >
              <span
                v-show="chineseList[index - 2].showContent"
                class="font-pinyin-medium pinyin"
                >{{ chineseList[index - 2].pinying }}</span
              >
              <span
                v-show="chineseList[index - 2].showContent"
                class="font-hanzi-medium"
                >{{ chineseList[index - 2].hanzi }}</span
              >
            </div>
            <img class="arrow" :src="arrowImage" />
          </div>
        </div>

        <div class="secondLine">
          <div v-for="index in 4" :key="index" class="buttonBox leftbox">
            <img class="cloud" :src="cloudImage" />
            <div
              class="button buttonsLeft"
              @click="foundAnswer(index + 4)"
              v-bind:class="{
                yellowBG: chineseList[index + 4].clicked == true,
              }"
            >
              <span
                v-show="chineseList[index + 4].showContent"
                class="font-pinyin-medium pinyin"
                >{{ chineseList[index + 4].pinying }}</span
              >
              <span
                v-show="chineseList[index + 4].showContent"
                class="font-hanzi-medium"
                >{{ chineseList[index + 4].hanzi }}</span
              >
            </div>
          </div>
        </div>

        <div class="thirdLine">
          <div v-for="index in 5" :key="index" class="buttonBox topbox">
            <img class="cloud" :src="cloudImage" />
            <div
              class="button buttonTop"
              @click="foundAnswer(index + 8)"
              v-bind:class="{
                yellowBG: chineseList[index + 8].clicked == true,
              }"
            >
              <span
                v-show="chineseList[index + 8].showContent"
                class="font-pinyin-medium pinyin"
                >{{ chineseList[index + 8].pinying }}</span
              >
              <span
                v-show="chineseList[index + 8].showContent"
                class="font-hanzi-medium"
                >{{ chineseList[index + 8].hanzi }}</span
              >
            </div>
          </div>
        </div>

        <div class="fourthLine">
          <div v-for="index in 2" :key="index" class="buttonBox rightbox">
            <img v-if="index < 2" class="cloud" :src="cloudImage" />
            <div
              v-if="index < 2"
              class="button buttonsRight"
              @click="foundAnswer(index + 13)"
              v-bind:class="{
                yellowBG: chineseList[index + 13].clicked == true,
              }"
            >
              <span
                v-show="chineseList[index + 13].showContent"
                class="font-pinyin-medium pinyin"
                >{{ chineseList[index + 13].pinying }}</span
              >
              <span
                v-show="chineseList[index + 13].showContent"
                class="font-hanzi-medium"
                >{{ chineseList[index + 13].hanzi }}</span
              >
            </div>
            <img v-else class="treasure" :src="[isLastStep ? treasureImageOpen : treasureImage]" />
          </div>
        </div>

        <div class="wheelWrapper" @click="ClickedWheel">
          <img class="wheel" :src="wheelImage" />
          <div
            class="pointerWrapper"
            :style="{
              transform: 'rotate(' + wheelList[wheelPosition].position + 'deg)',
            }"
            :class="{
              rotateAnimation1: isStartAnimation && wheelPosition === 0,
              rotateAnimation2: isStartAnimation && wheelPosition === 1,
              rotateAnimation3: isStartAnimation && wheelPosition === 2,
              rotateAnimation4: isStartAnimation && wheelPosition === 3,
              rotateAnimation5: isStartAnimation && wheelPosition === 4,
            }"
          >
            <img class="pointer" :src="pointerImage" />
          </div>
        </div>
      </div>
    </div>

    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :numBers="answersfound"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
      @updateNumbers="updateNumbers($event)"
    ></PageButton>
  </div>
</template>
<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
export default {
  components: {
    PageButton,
  },
  props: {
    wheelList: {
      type: Array,
      require: true,
    },
    // answerList: {
    //   type: Array,
    //   require: true,
    // },
  },
  data() {
    return {
      wheelPosition: 0,
      answersfound: 0,
      draggedStroke: 0,
      found_1_Count: 0,
      found_2_Count: 0,
      found_3_Count: 0,
      found_4_Count: 0,
      found_5_Count: 0,

      total_1_Count: 3,
      total_2_Count: 3,
      total_3_Count: 2,
      total_4_Count: 3,
      total_5_Count: 4,

      isStartAnimation: false,

      isLastStep: false,

      pointerRotation: 0,

      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "look",
            startNumberRange: 0,
            endNumberRange: 5,
            numBerValueAfterClick: null,
          },
          {
            type: "next",
            startNumberRange: 5,
            endNumberRange: 6,
            numBerValueAfterClick: 6,
          },
        ],
      },

      question: 0,
      ShowPI: true,

      success_audio_sound: require("@/assets/audio/correct_answer.mp3"),

      wheelImage: require("@/assets/img/16-GAMES/G38-boardgame/wheel.svg"),
      pointerImage: require("@/assets/img/16-GAMES/G38-boardgame/pointer.svg"),
      cloudImage: require("@/assets/img/16-GAMES/G38-boardgame/cloud.svg"),
      arrowImage: require("@/assets/img/16-GAMES/G38-boardgame/arrow-left.svg"),
      treasureImage: require("@/assets/img/16-GAMES/G38-boardgame/treasure.svg"),
      treasureImageOpen: require("@/assets/img/16-GAMES/G38-boardgame/treasure-open.svg"),
      chineseList: [
        {
          id: 1,
          pinying: "èr",
          number: 2,
          hanzi: "二",
          clicked: false,
          showContent: false,
        },
        {
          id: 2,
          pinying: "sì",
          number: 4,
          hanzi: "四 ",
          clicked: false,
          showContent: false,
        },

        {
          id: 3,
          pinying: "yī",
          number: 1,
          hanzi: "一",
          clicked: false,
          showContent: false,
        },

        {
          id: 4,
          number: 5,
          pinying: "wǔ",
          hanzi: "五",
          clicked: false,
          showContent: false,
        },

        {
          id: 5,
          number: 3,
          pinying: "sān",
          hanzi: "三",
          clicked: false,
          showContent: false,
        },
        {
          id: 6,
          pinying: "èr",
          number: 2,
          hanzi: "二",
          clicked: false,
          showContent: false,
        },
        {
          id: 7,
          pinying: "yī",
          number: 1,
          hanzi: "一",
          clicked: false,
          showContent: false,
        },
        {
          id: 8,
          pinying: "sì",
          number: 4,
          hanzi: "四 ",
          clicked: false,
          showContent: false,
        },
        {
          id: 9,
          number: 5,
          pinying: "wǔ",
          hanzi: "五",
          clicked: false,
          showContent: false,
        },
        {
          id: 10,
          number: 3,
          pinying: "sān",
          hanzi: "三",
          clicked: false,
          showContent: false,
        },
        {
          id: 11,
          pinying: "sì",
          number: 4,
          hanzi: "四 ",
          clicked: false,
          showContent: false,
        },
        {
          id: 12,
          pinying: "èr",
          number: 2,
          hanzi: "二",
          clicked: false,
          showContent: false,
        },
        {
          id: 13,
          number: 5,
          pinying: "wǔ",
          hanzi: "五",
          clicked: false,
          showContent: false,
        },
        {
          id: 14,
          pinying: "yī",
          number: 1,
          hanzi: "一",
          clicked: false,
          showContent: false,
        },
        {
          id: 15,
          number: 5,
          pinying: "wǔ",
          hanzi: "五",
          clicked: false,
          showContent: false,
        },
      ],
      wheelSocketInfo: {},
      clickNumerSocketInfo: {},
    };
  },
  mounted() {
    this.displayButtonGroup(0);
    this.$bus.$on("clickGameWheel", () => {
      this.ClickedWheel("socket", true);
    });
    this.$bus.$on("clickGameNumber", ({ index }) => {
      this.foundAnswer(index, true);
    });
  },
  watch: {
    wheelSocketInfo: {
      handler() {
        const data = {
          clickType: 2002501,
          data: { value: this.socketInfo },
          text: "FindNumberGame点击wheelsocket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },

    clickNumerSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2002502,
          data: { value },
          text: "FindNumberGame点击numbersocket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  methods: {
    displayButtonGroup(group) {
      if (group == 0) {
        this.chineseList[0].showContent = true;
        this.chineseList[3].showContent = true;
        this.chineseList[7].showContent = true;
      } else if (group == 1) {
        this.chineseList[4].showContent = true;
        this.chineseList[11].showContent = true;
        this.chineseList[1].showContent = true;
      } else if (group == 2) {
        this.chineseList[14].showContent = true;
        this.chineseList[9].showContent = true;
        this.chineseList[12].showContent = true;
      } else if (group == 3) {
        this.chineseList[6].showContent = true;
        this.chineseList[2].showContent = true;
        this.chineseList[13].showContent = true;
      } else if (group == 4) {
        this.chineseList[5].showContent = true;
        this.chineseList[8].showContent = true;
        this.chineseList[10].showContent = true;
      }
    },

    numberFound(num) {
      if (num == 1) {
        this.found_1_Count++;
        if (this.found_1_Count == this.total_1_Count) {
          this.answersfound++;
        }
      } else if (num == 2) {
        this.found_2_Count++;
        if (this.found_2_Count == this.total_2_Count) {
          this.answersfound++;
        }
      } else if (num == 3) {
        this.found_3_Count++;
        if (this.found_3_Count == this.total_3_Count) {
          this.answersfound++;
        }
      } else if (num == 4) {
        this.found_4_Count++;
        if (this.found_4_Count == this.total_4_Count) {
          this.answersfound++;
        }
      } else if (num == 5) {
        this.found_5_Count++;
        if (this.found_5_Count == this.total_5_Count) {
          this.answersfound++;
        }
      }
    },
    ClickedWheel(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.wheelSocketInfo = {
          index: Math.random(),
        };
      }
      this.isStartAnimation = true;
      setTimeout(() => {
        this.isStartAnimation = false;
        if (this.wheelPosition < 4) {
          this.wheelPosition++;
          this.displayButtonGroup(this.wheelPosition);
        } else {
          this.wheelPosition = 0;
        }
      }, 1000);
    },
    foundAnswer(index, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickNumerSocketInfo = {
          value: Math.random(),
          index,
        };
      }
      if (
        this.chineseList[index].showContent == false ||
        this.chineseList[index].clicked == true
      )
        return;
      //  let elementList = this.$refs[`SI_${this.draggedStroke}`];
      //console.log("button clicked 1:" + this.chineseList[index].number + " 2: " + this.ObjectList[this.question].number)
      if (
        this.chineseList[index].number ==
        this.wheelList[this.wheelPosition].data
      ) {
        //  this.objectDisplay = this.ObjectList[this.question].image
        // this.ObjectList[this.question].showObj = false;
        playCorrectSound(true, false);

        this.timer = setTimeout(() => {
          if (this.question + 1 < this.chineseList.length) {
            this.question++;
            // this.objectDisplay = "";
          } else {
            this.isLastStep = true;
            playCorrectSound();

            startConfetti();
          }
          this.numberFound(this.chineseList[index].number);
          this.chineseList[index].clicked = true;
        }, 100);
      }

      if (this.answersfound == 5) {
        this.isLastStep = true;
      }
    },

    nextPage() {
      this.$bus.$emit("nextButton", true);
    },

    updateNumbers(value) {
      this.numBers = value;
    },
  },
};
</script>
<style lang="scss" scoped>
.yellowBG {
  background-color: #f4d737 !important;
  pointer-events: none;
}

.txt-py {
  // very large screen
  font-size: 2vh !important;
  padding-top: 10px;
  @media screen and (max-width: 2200px) {
    // external screen
    font-size: 2vh !important;
    padding-top: 5px;
  }
  @media screen and (max-width: 1500px) {
    // laptop
    font-size: 2vh !important;
  }
  @media screen and (max-width: 1300px) {
    // ipad
    font-size: 2vh !important;
  }
}

.txt-hz {
  // very large screen
  font-size: 3vh !important;
  @media screen and (max-width: 2200px) {
    // external screen
    font-size: 3vh !important;
  }
  @media screen and (max-width: 1500px) {
    // laptop
    font-size: 3vh !important;
  }
  @media screen and (max-width: 1300px) {
    // ipad
    font-size: 3vh !important;
  }
}

.mainCont {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
  padding: 10px 10px 30px 10px;
  border-radius: 56px;
  background: #fff;
  // height: 100%;
  // margin-top: 15px;
  // margin-left: 15px;
  // background-image: url("../../../common/img/lesson3/5-DRAG_N_DROP_TO_PLATE/background.svg");
  background: #fff;
  //   border-radius: 48px;
  position: relative;
  //   overflow: hidden;
}
.background {
  text-align: -webkit-center;
  //   position: absolute;
  height: 100%;
  width: 100%;
  background-image: url("../../../../assets/img/16-GAMES/G38-boardgame/background.svg");
  border-radius: 56px;
  background-color: #f7f4e0;
  margin: auto;
  overflow: hidden;
  position: relative;
}
.backgroundImg_n_DragArea {
  position: relative;
  width: -moz-fit-content;
  //   background-color: #214E96;
  width: 78vh;
  height: 65vh;
  top: 9vh;
  /* left: 10%; */
  flex-direction: column;
  border-radius: 30px;
  display: flex;

  // img {
  //   position: relative;
  //   height: 100%;
  //   text-align: -webkit-center;
  // }
}
.firstLine {
  //  background-color: red;
  height: 20%;
  width: 100%;
  bottom: 0;
  position: absolute;
  display: flex;
  flex-direction: row-reverse;
}

.secondLine {
  //  background-color: black;
  height: 80%;
  width: 16.66%;
  left: 0;
  top: 0;
  position: absolute;
  display: flex;
  flex-direction: column-reverse;
}
.thirdLine {
  //  background-color: green;
  height: 20%;
  width: 83.33%;
  right: 0;
  top: 0;
  position: absolute;
  display: flex;
}
.fourthLine {
  // background-color: blue;
  height: 40%;
  width: 16.66%;
  right: 0;
  top: 20%;
  position: absolute;
}

.txt-py {
  font-size: 2vh !important;
}

.txt-hz {
  font-size: 4vh !important;
}
.buttonBox {
  height: 100%;
  width: 16.66%;
  // background-color: antiquewhite;
  //border: groove;
  .button {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    color: #6a6a6a;
    height: 90%;
    background-color: #cfc8b0;
    width: 14%;
    position: absolute;
    top: 5%;
    margin-left: 2%;
    z-index: 2;
    border-radius: 15%;
    &:hover {
      cursor: pointer;
    }
  }
  .arrow {
    right: 10%;
    top: 36%;
    height: 26%;
    position: absolute;
  }
  .treasure {
    position: absolute;
    width: 120%;
    margin-top: 25%;
    left: -12%;
  }
  .cloud {
    position: relative;
    top: -15%;
    left: -15%;
    width: 130%;
    height: 130%;
  }
}

.buttonsLeft {
  top: auto !important;
  margin-top: -125% !important;
  height: 23% !important;
  width: 86% !important;
  margin-top: -134% !important;
  margin-left: 11% !important;
  position: absolute !important;
}
.buttonsRight {
  top: auto !important;
  margin-top: -125% !important;
  height: 42% !important;
  width: 86% !important;
  margin-top: -134% !important;
  margin-left: 11% !important;
  position: absolute !important;
}
.buttonTop {
  width: 17% !important;
}
.leftbox {
  height: 25%;
  width: 100%;
}
.topbox {
  width: 20%;
}
.rightbox {
  width: 100%;
  height: 50%;
}

.wheelWrapper {
  position: absolute;
  height: 23vh;
  width: 23vh;
  // background-color: brown;
  top: 30%;
  left: 40%;
  cursor: pointer;
  .wheel {
    // position: absolute;
    height: 100%;
    width: 100%;
    &:hover {
      cursor: pointer;
    }
  }
  .pointerWrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    transform: rotate(0deg);
    .pointer {
      width: 30%;
      top: 34%;
      position: absolute;
      left: 35%;
      transform: rotate(-90deg);
    }
  }
  .rotateAnimation1 {
    animation: rotateAnimation1 1s ease-out;
  }
  .rotateAnimation2 {
    animation: rotateAnimation2 1s ease-out;
  }
  .rotateAnimation3 {
    animation: rotateAnimation3 1s ease-out;
  }
  .rotateAnimation4 {
    animation: rotateAnimation4 1s ease-out;
  }
  .rotateAnimation5 {
    animation: rotateAnimation5 1s ease-out;
  }

  @keyframes rotateAnimation1 {
    0% {
      transform: rotate(-37.5deg);
    }

    100% {
      // transform: rotate(540deg);
      transform: rotate(900deg);
    }
  }
  @keyframes rotateAnimation2 {
    0% {
      transform: rotate(180deg);
    }

    100% {
      // transform: rotate(615deg);
      transform: rotate(975deg);
    }
  }
  @keyframes rotateAnimation3 {
    0% {
      transform: rotate(255deg);
    }

    100% {
      // transform: rotate(757.5deg);
      transform: rotate(1117.5deg);
    }
  }
  @keyframes rotateAnimation4 {
    0% {
      transform: rotate(37.5deg);
    }

    100% {
      // transform: rotate(472.5deg);
      transform: rotate(832.5deg);
    }
  }
  @keyframes rotateAnimation5 {
    0% {
      transform: rotate(112.5deg);
    }

    100% {
      // transform: rotate(682.5deg);
      transform: rotate(1042.5deg);
    }
  }
}
</style>
