<template>
  <div class="story-page-one-container">
    <StoryPage :bgImg="bgImg" />
  </div>
</template>

<script>
import StoryPage from "@/components/Course/CoursePage/StoryPage";
export default {
  name: "StoryPageOne",
  components: {
    StoryPage,
  },
  data() {
    return {
      bgImg: require("@/assets/img/13-Video/chapter-01/lesson-03-video-1.svg"),
    };
  },
};
</script>

<style lang="scss" scoped>
.story-page-one-container {
  width: 100%;
  height: 100%;
}
</style>
