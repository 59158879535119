<template>
  <div class="game-container">
    <FindNumberGame :wheelList="wheelList" :elementList="elementList" />
  </div>
</template>

<script>
import FindNumberGame from "@/components/Course/GamePage/FindNumberGame";
export default {
  data() {
    return {
      wheelList: [
        {
          id: 6,
          data: 1,
          position: 322.5,
        },

        {
          id: 3,
          data: 4,
          position: 180,
        },

        {
          id: 5,
          data: 5,
          position: 255,
        },
        {
          id: 1,
          data: 2,
          position: 37.5,
        },
        {
          id: 2,
          data: 3,
          position: 112.5,
        },
      ],

      elementList: [
        {
          id: 1,
          pinying: "èr",
          number: 2,
          hanzi: "二",
          clicked: false,
          isShow: false,
        },
        {
          id: 2,
          pinying: "sì",
          number: 4,
          hanzi: "四 ",
          clicked: false,
          isShow: false,
        },

        {
          id: 3,
          pinying: "yī",
          number: 1,
          hanzi: "一",
          clicked: false,
          isShow: false,
        },

        {
          id: 4,
          number: 5,
          pinying: "wǔ",
          hanzi: "五",
          clicked: false,
          isShow: false,
        },

        {
          id: 5,
          number: 3,
          pinying: "sān",
          hanzi: "三",
          clicked: false,
          isShow: false,
        },
        {
          id: 6,
          pinying: "èr",
          number: 2,
          hanzi: "二",
          clicked: false,
          isShow: false,
        },
        {
          id: 7,
          pinying: "yī",
          number: 1,
          hanzi: "一",
          clicked: false,
          isShow: false,
        },
        {
          id: 8,
          pinying: "sì",
          number: 4,
          hanzi: "四 ",
          clicked: false,
          isShow: false,
        },
        {
          id: 9,
          number: 5,
          pinying: "wǔ",
          hanzi: "五",
          clicked: false,
          isShow: false,
        },
        {
          id: 10,
          number: 3,
          pinying: "sān",
          hanzi: "三",
          clicked: false,
          isShow: false,
        },
        {
          id: 11,
          pinying: "sì",
          number: 4,
          hanzi: "四 ",
          clicked: false,
          isShow: false,
        },
        {
          id: 12,
          pinying: "èr",
          number: 2,
          hanzi: "二",
          clicked: false,
          isShow: false,
        },
        {
          id: 13,
          number: 5,
          pinying: "wǔ",
          hanzi: "五",
          clicked: false,
          isShow: false,
        },
        {
          id: 14,
          pinying: "yī",
          number: 1,
          hanzi: "一",
          clicked: false,
          isShow: false,
        },
        {
          id: 15,
          number: 5,
          pinying: "wǔ",
          hanzi: "五",
          clicked: false,
          isShow: false,
        },
      ],
    };
  },
  components: {
    FindNumberGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











