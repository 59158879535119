<template>
  <div class="game-container">
    <RecognitionNumberGame
      :ObjectList="ObjectList"
      :chineseList="chineseList"
    />
  </div>
</template>

<script>
import RecognitionNumberGame from "@/components/Course/GamePage/RecognitionNumberGame";
export default {
  data() {
    return {
      ObjectList: [
        {
          id: 4,
          image: require("@/assets/img/16-GAMES/G41-choose-abcde/game-four.svg"),
          number: 4,
          showObj: true,
        },
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G41-choose-abcde/game-two.svg"),
          number: 2,
          showObj: true,
        },
        {
          id: 3,
          image: require("@/assets/img/16-GAMES/G41-choose-abcde/game-three.svg"),
          number: 3,
          showObj: true,
        },
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G41-choose-abcde/game-one.svg"),
          number: 1,
          showObj: true,
        },
        {
          id: 5,
          image: require("@/assets/img/16-GAMES/G41-choose-abcde/game-five.svg"),
          number: 5,
          showObj: true,
        },
      ],

      chineseList: [
                {
          id: 1,
          pinying: "sì",
          number: 4,
          hanzi: "四",
        },

        {
          id: 5,
          pinying: "sān",
          number: 3,
          hanzi: "三",
        },

        {
          id: 3,
          pinying: "yī",
          number: 1,
          hanzi: "一",
        },
        {
          id: 2,
          pinying: "wǔ",
          number: 5,
          hanzi: "五",
        },
                {
          id: 4,
          pinying: "èr",
          number: 2,
          hanzi: "二",
        },
      ],
    };
  },
  components: {
    RecognitionNumberGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











